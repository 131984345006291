import * as React from "react";
import { Helmet } from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import ProjectTile from '../components/ProjectTile'
import { motion } from 'framer-motion'

// This is the template for displaying an individual tag
// see /pages/tags/index.js for the template for displaying the tags page (ALL tags)
const TagRoute = (props) =>  {
	// list of all posts
	const { edges: posts } = props.data.allMarkdownRemark

	// Iterate over all posts
	const postLinks = posts.map(({node: post}, index) => (
		<ProjectTile post={post} key={post.id} index={index} />
	))

	const { tag } = props.pageContext
	const { title } = props.data.site.siteMetadata
	const { totalCount } = props.data.allMarkdownRemark

	// 3 posts tagged with “Back-End”
	const tagHeader = `${totalCount} post${totalCount === 1 ? '' : 's'} tagged with “${tag}”`

	return (
		<Layout>
			<section className='section'>
				<Helmet title={`${tag} | ${title}`} />
				<div className='container content'>
					<div className='columns'>
						<div className='column is-10 is-offset-1' style={{ marginBottom: '6rem' }}>
							<h3 className='title is-size-4 is-bold-light'>{tagHeader}</h3>
							<motion.div layout className='grid is-col-min-12 is-gap-3'>
								{postLinks}
							</motion.div>

							{/* List of links to all posts with the selected tag */}
							{/* Currently just displays as text */}
							{/* <ul className='taglist'>{postLinks}</ul> */}

							<div className='column is-12 has-text-centered mt-5'>
								<Link className='button is-primary is-outlined' to='/tags'>
									Browse All Tags
								</Link>
							</div>

						</div>
					</div>
				</div>
			</section>
		</Layout>
	)
}

export default TagRoute;

export const tagPageQuery = graphql`
	query TagPage($tag: String) {
		site {
			siteMetadata {
				title
			}
		}
		allMarkdownRemark(
			limit: 1000
			sort: { fields: [frontmatter___date], order: DESC }
			filter: { frontmatter: { tags: { in: [$tag] }, hidden: { ne: true } } }
		) {
			totalCount
			edges {
				node {
					id
					fields {
						slug
					}
					frontmatter {
						title
						subtitle
						hidden
						featured
						image {
							childImageSharp {
								gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED) # CONSTRAINED, FIXED, FULL_WIDTH
							}
						}
					}
				}
			}
		}
	}
`
